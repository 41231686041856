import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Divider,
  Navbar,
  NavbarProps,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconCirclePlus,
  IconHome,
  IconLogout,
  IconSearch,
  IconSettings,
  IconTimelineEventText,
  IconUsers,
} from "@tabler/icons-react";
import Router from "next/router";
import React from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";
import NavButton from "../Button/NavButton";
import useFeatureFlag from "../hooks/useFeatureFlag";
import Logo from "../Logo/Logo";
import MemoryEditModal from "../Modals/MemoryEdit";

const LOGOUT_REDIRECT_URI = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT_URI;

export type AppShellProps = Omit<NavbarProps, "children">;

export default function AppNavProps({ ...others }: AppShellProps) {
  const theme = useMantineTheme();
  const { logout } = useAuth0();

  const showMemorySearch = useFeatureFlag({
    flagName: "memory-search",
    defaultValue: false,
  });

  interface NavItemProperties {
    label: string;
    icon: React.ReactNode;
    onClick?: (any) => void;
    grow?: boolean;
    wrapper?: (children: React.ReactNode) => React.ReactNode;
    exclude?: boolean;
  }

  const navItems: NavItemProperties[] = [
    {
      label: "Dashboard",
      icon: <IconHome color="black" />,
      onClick: () => {
        Router.push(PAGE_ROUTES.DASHBOARD);
      },
    },
    {
      label: "Memories",
      icon: <IconTimelineEventText color="black" />,
      onClick: () => {
        Router.push(PAGE_ROUTES.MEMORIES);
      },
    },
    {
      label: "Search",
      icon: <IconSearch color="black" />,
      onClick: () => {
        Router.push(PAGE_ROUTES.SEARCH);
      },
      exclude: !showMemorySearch,
    },
    {
      label: "Children",
      icon: <IconUsers color="black" />,
      onClick: () => {
        Router.push(PAGE_ROUTES.CHILDREN);
      },
    },
    {
      label: "New Memory",
      icon: <IconCirclePlus color="black" />,
      grow: true,
      wrapper: (children) => (
        <MemoryEditModal
          allowEdit={true} // TODO: refactor this out. the modal should not do the blocking...
          data={{}} // TODO: refactor this out. the modal should not do the blocking...
        >
          {children}
        </MemoryEditModal>
      ),
    },
    {
      label: "Settings",
      icon: <IconSettings color="black" />,
      onClick: () => {
        Router.push(PAGE_ROUTES.SETTINGS);
      },
    },
    {
      label: "Logout",
      icon: <IconLogout color="black" />,

      onClick: () => {
        logout({ logoutParams: { returnTo: LOGOUT_REDIRECT_URI } });
      },
    },
  ];

  const navItemContent = (item: NavItemProperties) => {
    const body = (
      <Box>
        <NavButton leftIcon={item.icon} variant="subtile" size="sm">
          <Text size="15px" fw={300} color={theme.colors.gray[7]}>
            {item.label}
          </Text>
        </NavButton>
      </Box>
    );
    return item.wrapper?.(body) ?? body;
  };

  return (
    <Navbar {...others}>
      <Navbar.Section mb="sm" onClick={() => Router.push(PAGE_ROUTES.LANDING)}>
        <Box>
          <Logo
            sx={{
              height: "30px",
            }}
          />
        </Box>
      </Navbar.Section>

      <Divider size="sm" />

      {navItems.map(
        (item) =>
          !item.exclude && (
            <Navbar.Section
              mt="sm"
              onClick={item.onClick}
              grow={item.grow}
              key={item.label}
            >
              {navItemContent(item)}
            </Navbar.Section>
          )
      )}
    </Navbar>
  );
}
