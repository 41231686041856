import {
  Box,
  Group,
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { ReactElement } from "react";

export interface ModalProps
  extends Omit<MantineModalProps, "opened" | "onClose"> {
  title?: string;
  openButton: ReactElement;
  // Optional path to provide the modal body controller access to the open/closer
  modalControl?: readonly [
    boolean,
    {
      readonly open: () => void;
      readonly close: () => void;
      readonly toggle: () => void;
    }
  ];
}

function Modal({
  children,
  title,
  openButton,
  modalControl,
  ...others
}: ModalProps) {
  const [opened, { open, close }] = modalControl ?? useDisclosure(false);
  const theme = useMantineTheme();

  return (
    <>
      <MantineModal
        opened={opened}
        onClose={close}
        title={title}
        overlayProps={{
          color: theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        transitionProps={{ transition: "fade", duration: 200 }}
        radius={theme.radius.md}
        size="auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...others}
      >
        <Box sx={{ maxWidth: "600px" }}>{children}</Box>
      </MantineModal>

      <Group
        position="center"
        onClick={(e) => {
          e.stopPropagation();
          open();
        }}
      >
        {openButton}
      </Group>
    </>
  );
}

export default Modal;
