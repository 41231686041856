import Image from "next/image";
import Router from "next/router";
import React from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";
import LogoSVG from "../../public/brand/logo.svg";

interface LogoProps {
  sx?: any;
}

function Logo({ sx }: LogoProps) {
  return (
    <Image
      priority
      alt="logo"
      src={LogoSVG}
      onClick={() => {
        Router.push(PAGE_ROUTES.LANDING);
      }}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        // https://codepen.io/sosuke/pen/Pjoqqp
        // https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element?rq=1
        filter:
          "invert(19%) sepia(75%) saturate(5232%) hue-rotate(203deg) brightness(81%) contrast(120%)",
        ...sx,
      }}
    />
  );
}

export default Logo;
