import qs from "qs";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import AuthContext from "../../contexts/Auth";
import ScreenCover from "../ScreenCover/ScreenCover";
import Spinner from "../Spinner/Spinner";

// todo: this should be a component
const loadingIndicator = (
  <ScreenCover>
    <Spinner />
  </ScreenCover>
);

interface WithAuthenticationProps {
  children: ReactElement;
  allowUnauthenticated?: boolean;
}

function WithAuthentication({
  children,
  allowUnauthenticated = false,
}: WithAuthenticationProps) {
  // if we allow this page to be viewed without authentication then the page is
  // considered ready immediately.
  const [canShowPage, setCanShowPage] = useState(allowUnauthenticated);
  const { accessToken, acquireAccessToken } = useContext(AuthContext);

  const triggerAuthentication = async () => {
    // if we have not yet signaled that the page is ready to show, do that now
    // that we have an access token
    if (!canShowPage && accessToken) {
      // console.log("setReady true upper");
      setCanShowPage(true);
      return;
    }

    // determine if we should route to login or signup
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const showSignUp = !!query.new;
    const shouldLoginOnFailure = !allowUnauthenticated;
    // was blocked indicates that acquireAccessToken took no action and we
    // should not react to the system state after it completes
    const wasBlocked = await acquireAccessToken(
      shouldLoginOnFailure,
      showSignUp
    );
    if (!wasBlocked && allowUnauthenticated) {
      console.log("setReady true lower");
      setCanShowPage(true);
      // do not return here so that pages that don't require auth can still
      // conditionally show authenticated content
    }
  };

  useEffect(() => {
    triggerAuthentication();
  }, [accessToken]);

  if (!canShowPage) {
    return loadingIndicator;
  }
  return children;
}

export default WithAuthentication;
