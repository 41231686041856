import { Box } from "@mantine/core";
import Image from "next/image";
import React, { useRef, useState } from "react";
import { isSafari } from "react-device-detect";

function Video({ hidePlayIcon = false, src, ...others }: any) {
  const videoRef: React.RefObject<HTMLVideoElement> = useRef(null);
  const [showPlayIcon, setShowPlayIcon] = useState(!hidePlayIcon && !isSafari);

  const captureAction = () => {
    const isPaused = videoRef?.current?.paused;
    if (isPaused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const onVideoPlay = () => {
    setShowPlayIcon(false);
  };

  // capture the clicks in the wrapping parent to prevent them from triggering
  // things like a modal further up in the tree
  return (
    <Box style={{ position: "relative" }} onClick={(e) => e.stopPropagation()}>
      {showPlayIcon && (
        <Image
          alt=""
          width="60"
          height="60"
          src={"/images/play-overlay.png"}
          style={{
            position: "absolute",
            top: "calc(50% - 30px)",
            left: "calc(50% - 30px)",
            width: "60px",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={captureAction}
        />
      )}
      <video
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        controls
        // Touches must be handled separately
        onTouchEnd={captureAction}
        onPlay={onVideoPlay}
        ref={videoRef}
        {...others}
      >
        <source src={src}></source>
        Your browser does not support video
      </video>
    </Box>
  );
}

export default Video;
