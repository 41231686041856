import { Context, useContext, useState } from "react";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import media from "../../models/media";
import * as Api from "../API/Api";

interface useChildListProps {
  context?: Context<AuthContextProps>;
  media?: media[];
}

export default function useMediaList({
  context = AuthContext,
  media,
}: useChildListProps): [
  media[],
  (m: media) => void,
  () => Promise<void>,
  () => void
] {
  const { accessToken } = useContext(context);

  const [mediaList, setMediaList] = useState<media[]>(media);

  // const load = async () => {
  //   if (!accessToken) {
  //     return;
  //   }
  //   const { Children: list } = await Api.getAllChildren({ accessToken });

  //   setChildList(list);
  // };

  // useEffect(() => {
  //   load();
  // }, [accessToken]);

  const remove = (m: media) => {
    if (!mediaList) {
      return;
    }
    const idx = mediaList.indexOf(m);
    setMediaList([
      ...mediaList.slice(0, idx),
      {
        ...mediaList[idx],
        Deleted: true,
      },
      ...mediaList.slice(idx + 1),
    ]);
  };

  const commitRemovals = async () => {
    if (!mediaList) {
      return;
    }
    for (let i = 0; i < mediaList?.length || 0; i++) {
      const m = mediaList[i];
      if (m.Deleted) {
        await Api.deleteMedia({
          accessToken,
          media: m,
        });
      }
    }
  };

  const resetRemovals = () => {
    if (!mediaList) {
      return;
    }
    const reset = mediaList.map((m) => ({ ...m, Deleted: false }));
    setMediaList(reset);
  };

  return [mediaList, remove, commitRemovals, resetRemovals];
}
