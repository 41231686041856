import axios from "axios";
import { Context, useContext, useState } from "react";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import * as Api from "../API/Api";

interface useFileUploadOptions {
  context?: Context<AuthContextProps>;
  file?: File;
}

export default function useFileUpload({
  context = AuthContext,
  file,
}: useFileUploadOptions): [
  File,
  (file: File) => void,
  (memoryId: string) => Promise<void>,
  { isUploading: boolean; progress: number }
] {
  const { accessToken } = useContext(context);

  const [currentFile, setCurrentFile] = useState<File>(file);
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    progress: 0,
  });

  const onUploadProgress = (progress) => {
    const newProgress = (progress.loaded / progress.total) * 100;
    if (newProgress < 100) {
      setUploadStatus({
        ...uploadStatus,
        isUploading: true,
        progress: newProgress,
      });
    }
  };

  const sendUpload = async (memoryId: string, file: File, uploadConfig) => {
    setUploadStatus({
      isUploading: true,
      progress: 0,
    });
    // upload the file and report progress
    await axios.put(uploadConfig.URL, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress,
    });
    // attach the upload to the given memory
    await Api.attachUpload({
      accessToken,
      attachment: {
        MemoryID: memoryId,
        ContentType: currentFile.type,
        FileName: currentFile.name,
        Key: uploadConfig.Key,
      },
    });
    // clear the upload status and file handle
    setUploadStatus({
      ...uploadStatus,
      isUploading: false,
      progress: 0,
    });
    setCurrentFile(null);
  };

  const upload = async (memoryId: string) => {
    if (!currentFile) {
      // nothing to upload
      return;
    }
    const uploadConfig = await Api.getUploadUrl({
      accessToken,
      fileMeta: {
        FileName: currentFile.name,
        ContentType: currentFile.type,
      },
    });
    await sendUpload(memoryId, currentFile, uploadConfig);
  };

  return [currentFile, setCurrentFile, upload, uploadStatus];
}
