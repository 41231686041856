import {
  Box,
  FileInput,
  Grid,
  Image,
  Progress,
  Select,
  Text,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconAlertTriangle,
  IconCalendar,
  IconEraser,
  IconNotebook,
  IconTrashX,
} from "@tabler/icons-react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import useChildList from "../../components/hooks/useChildList";
import useMemory from "../../components/hooks/useMemory";
import AuthContext from "../../contexts/Auth";
import {
  MemoryDeletedSignal,
  MemoryUpdatedSignal,
} from "../../contexts/Signals";
import Button from "../Button/Button";
import DangerButton from "../Button/DangerButton";
import useFileUpload from "../hooks/useFileUpload";
import useMediaList from "../hooks/useMediaList";
import Modal from "../Modal/Modal";
import Video from "../Video/Video";

// const blankMemory = { ChildID: "", Message: "", Timestamp: moment() };

interface memoryEditProps {
  children: any;
  data: any;
  isOpen?: boolean;
  allowEdit: boolean;
}

function MemoryEdit({
  children,
  data = {},
  isOpen = false,
  allowEdit = false, // todo : support this
}: memoryEditProps) {
  const theme = useMantineTheme();
  const { accessToken } = useContext(AuthContext);

  const modalControl = useDisclosure(!!isOpen);
  const [, { close: modalCloser }] = modalControl;

  const memoryUpdatedPublishSignal = MemoryUpdatedSignal.usePublish();
  // const memoryCreatedPublishSignal = MemoryCreatedSignal.usePublish();
  const memoryDeletedPublishSignal = MemoryDeletedSignal.usePublish();

  const [memory, setMemoryData, saveMemory, deleteMemory] = useMemory(data);
  const [mediaList, removeMedia, commitMediaRemovals, resetMediaRemovals] =
    useMediaList(data);
  const childList = useChildList();
  const [currentFile, setCurrentFile, upload, uploadStatus] = useFileUpload({});

  const [showDelete, setShowDelete] = useState(false);

  const popMessage = ({ message, color = "grape", title = "Nice!" }) => {
    notifications.show({
      title,
      message,
      color,
    });
  };

  useEffect(() => {
    // if we don't have the child list yet
    // or if this is an existing memory
    // don't set the child id
    if (!childList || memory?.ChildID) {
      return;
    }
    // initialize the child id to the first child in the list
    setMemoryData({
      ...memory,
      ChildID: childList?.[0]?.ID,
    });
  }, [childList, open]);

  const closeModal = () => {
    modalCloser();
    setCurrentFile(undefined);
    resetMediaRemovals();
    setShowDelete(false);
  };

  const saveMemoryData = async () => {
    const updatedMemoryData = await saveMemory();
    await commitMediaRemovals();
    await upload(updatedMemoryData.ID);

    popMessage({ message: "Memory saved!" });
    closeModal();
    memoryUpdatedPublishSignal(updatedMemoryData);
  };

  const deleteMemoryData = async () => {
    await deleteMemory();
    closeModal();
    memoryDeletedPublishSignal(true);
  };

  const onSelectedChildUpdate = async (newChildId) => {
    setMemoryData({
      ...memory,
      ChildID: newChildId,
    });
  };

  const onTimestampChange = (newDate) => {
    setMemoryData({
      ...memory,
      Timestamp: newDate,
    });
  };

  const onMessageChange = (e) => {
    setMemoryData({
      ...memory,
      Message: e.target.value,
    });
  };

  // TODO" Move out to separate file
  const mediaElements = mediaList?.map((m) => {
    if (m.Deleted) {
      return;
    }
    const mediaPreview = (() => {
      if (m.ContentType.startsWith("image/")) {
        return <Image fit="contain" maw={40} mah={40} src={m.URL} />;
      } else if (m.ContentType.startsWith("video/")) {
        if (m.ContentType === "video/3gpp") {
          return (
            <Box
              sx={{ textAlign: "center", color: theme.colors.gray[5] }}
              key={m.ID}
            >
              <Text variant="caption">
                videos uploaded from SMS are not supported
              </Text>
            </Box>
          );
        }

        const checkAndSet = async () => {
          try {
            await axios.head(m.HeadURL);
            // if successful
            m.Disabled = false;
          } catch (e) {
            setTimeout(() => {
              checkAndSet();
            }, 3000);
          }
        };
        const handleImageError = (e) => {
          // console.log(e);
          e.target.onerror = null;
          e.target.style.display = "none";
          m.Disabled = true;
          setTimeout(() => {
            checkAndSet();
          }, 3000);
        };

        return (
          <>
            {!m.Disabled && (
              <Box>
                <Video
                  hidePlayIcon={true}
                  width="40px"
                  height="40px"
                  onError={handleImageError}
                  // onClick={(e) => e.stopPropagation()}
                  // #t=0.001 is a hack to ensure the video thumbnail loads
                  // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
                  src={`${m.URL}#t=0.001`}
                  style={{ maxHeight: "320px" }}
                />
              </Box>
            )}
          </>
        );
      }
    })();
    return (
      <Box key={m.ID}>
        <Grid>
          <Grid.Col span={6}>{mediaPreview}</Grid.Col>
          <Grid.Col span={6} sx={{ textAlign: "right" }}>
            <DangerButton
              disabled={uploadStatus.isUploading}
              onClick={() => removeMedia(m)}
            >
              <IconTrashX />
            </DangerButton>
          </Grid.Col>
        </Grid>
      </Box>
    );
  });

  // If the user is not allowed to edit then don't even render the modal
  if (!allowEdit) {
    return (
      <Box
        onClick={() => {
          if (!accessToken) {
            popMessage({
              color: "red",
              title: "Oh no!",
              message: "Must be logged in to edit memory!",
            });
          } else {
            popMessage({
              color: "red",
              title: "Oh no!",
              message: "Unable to edit memory. Please reload.",
            });
          }
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <>
      <Modal
        title={memory?.ID ? "Edit Memory" : "New Memory"}
        openButton={<Box sx={{ width: "100%" }}>{children}</Box>}
        modalControl={modalControl}
      >
        {showDelete && (
          <>
            <Box mt="md">
              <Text weight={600}>Are you sure?</Text>
              <Text mt="sm">
                This memory and media attached to it will become unavailable.
              </Text>
            </Box>

            <Box mt="xl">
              <Grid>
                <Grid.Col span={6}>
                  <Button onClick={() => setShowDelete(false)}>Go back</Button>
                </Grid.Col>
                <Grid.Col span={6} ta="right">
                  <DangerButton
                    leftIcon={<IconAlertTriangle />}
                    onClick={() => deleteMemoryData()}
                  >
                    Delete
                  </DangerButton>
                </Grid.Col>
              </Grid>
            </Box>
          </>
        )}

        {!showDelete && (
          <>
            <Grid mt="md">
              <Grid.Col span={12}>
                <Select
                  label="Child"
                  placeholder="Child"
                  data={
                    childList?.map((c) => ({
                      label: c.FirstName,
                      value: c.ID,
                    })) ?? []
                  }
                  value={memory.ChildID}
                  onChange={onSelectedChildUpdate}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <DateInput
                  size="xs"
                  icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                  label="Date"
                  onChange={onTimestampChange}
                  radius={theme.radius.md}
                  value={new Date(memory.Timestamp)}
                  mx="auto"
                  maw={400}
                  wrapperProps={{ sx: { minWidth: "100%" } }}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Textarea
                  required
                  id="message"
                  label="Message"
                  placeholder="Message ..."
                  // icon={<IconSignature />}
                  name="Message"
                  radius={theme.radius.md}
                  size="lg" // todo: pull this from theme
                  value={memory.Message}
                  autosize
                  minRows={2}
                  maxRows={4}
                  onChange={onMessageChange}
                />
              </Grid.Col>

              <Grid.Col mt="md" span={12}>
                {mediaElements}
              </Grid.Col>
              <Grid.Col span={12} mt="md">
                {!uploadStatus.isUploading && (
                  <>
                    {!currentFile && (
                      <Box>
                        <Text weight={300}>Attach media</Text>
                        <FileInput
                          accept="image/png,image/jpeg,image/gif,video/mp4,video/quicktime"
                          placeholder="Pic or Video"
                          onChange={setCurrentFile}
                        />
                      </Box>
                    )}
                    <Box>
                      <Grid>
                        <Grid.Col span={9}>
                          <Text weight={300} sx={{ fontSize: "0.8rem" }}>
                            {currentFile?.name}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={3} sx={{ textAlign: "right" }}>
                          {currentFile && (
                            <DangerButton onClick={() => setCurrentFile(null)}>
                              <IconTrashX />
                            </DangerButton>
                          )}
                        </Grid.Col>
                      </Grid>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    display: uploadStatus.isUploading ? "block" : "none",
                    overflowX: "auto",
                  }}
                >
                  <Text variant="caption" sx={{ fontSize: "0.8rem" }}>
                    uploading...
                  </Text>
                  <Progress
                    value={uploadStatus.progress}
                    color="grape"
                    animate
                  />
                </Box>
              </Grid.Col>
            </Grid>
            <Grid mt="xl">
              <Grid.Col span={6}>
                {memory?.ID && !uploadStatus.isUploading && (
                  <DangerButton
                    leftIcon={<IconEraser />}
                    onClick={() => setShowDelete(true)}
                  >
                    Delete
                  </DangerButton>
                )}
              </Grid.Col>
              <Grid.Col span={6} ta="right">
                {memory?.ID && !uploadStatus.isUploading && (
                  <Button leftIcon={<IconNotebook />} onClick={saveMemoryData}>
                    Update
                  </Button>
                )}

                {!memory?.ID && (
                  <Button leftIcon={<IconNotebook />} onClick={saveMemoryData}>
                    Create
                  </Button>
                )}
              </Grid.Col>
            </Grid>
          </>
        )}
      </Modal>
    </>
  );
}

export default MemoryEdit;
