import Head from "next/head";
import React, { ReactNode } from "react";

import AppShell from "../AppShell/AppShell";
import WithAuthentication from "../WithAuthentication/WithAuthentication";

interface PageProps {
  children?: ReactNode;
  pageName: string;
  fullScreen?: boolean;
  allowUnauthenticated?: boolean;
}

function Page({
  children,
  pageName,
  fullScreen = false,
  allowUnauthenticated = false,
}: PageProps) {
  return (
    <>
      {pageName && (
        <Head>
          <title>{pageName}</title>
        </Head>
      )}
      <WithAuthentication allowUnauthenticated={allowUnauthenticated}>
        <AppShell pageName={pageName} fullScreen={fullScreen}>
          {children}
        </AppShell>
      </WithAuthentication>
    </>
  );
}

export default Page;
