import {
  Container,
  ContainerProps,
  Grid,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import Router from "next/router";
import React from "react";

import { PAGE_ROUTES } from "../../constants/Navigation";

function Footer({ ...others }: Omit<ContainerProps, "children">) {
  const theme = useMantineTheme();
  return (
    <Container {...others}>
      <Grid sx={{ color: theme.colors.gray[6] }}>
        <Grid.Col xs={12} md={6}>
          <Title order={6} fw={400}>
            MiniMemories
          </Title>
          <Text fw={200}>
            copyright &copy; {new Date().getFullYear()} MiniMemories.
            <br />
            All rights reserved.
          </Text>
        </Grid.Col>
        <Grid.Col xs={12} md={3}>
          <Text fw={300} onClick={() => Router.push(PAGE_ROUTES.DASHBOARD)}>
            Sign in
          </Text>
          <Text
            fw={200}
            onClick={() => Router.push("mailto:contact@minimemories.me")}
          >
            Contact us
          </Text>
        </Grid.Col>

        <Grid.Col xs={12} md={3}>
          <Text
            fw={200}
            onClick={() => Router.push(PAGE_ROUTES.TERMS_OF_SERVICE)}
          >
            Terms of Service
          </Text>
          <Text
            fw={200}
            onClick={() => Router.push(PAGE_ROUTES.PRIVACY_POLICY)}
          >
            Privacy Policy
          </Text>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default Footer;
