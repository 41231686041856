import moment from "moment";
import { Context, useContext, useState } from "react";

import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import media from "../../models/media";
import memory from "../../models/memory";
import * as Api from "../API/Api";

const blankMemory = {
  ChildID: "",
  Message: "",
  Timestamp: moment().format(),
} as memory;

interface useMemoryOptions {
  context: Context<AuthContextProps>;
  memory?: memory;
  media?: media[];
}

export default function useMemory({
  context = AuthContext,
  memory,
}: useMemoryOptions): [
  memory,
  (memory: memory) => void,
  () => Promise<memory>,
  () => Promise<void>
] {
  const { accessToken } = useContext(context);
  const [memoryData, setMemoryData] = useState<memory>(
    memory ?? { ...blankMemory }
  );

  const saveMemory = async (): Promise<memory> => {
    let updatedMemory;
    if (memoryData?.ID) {
      updatedMemory = await Api.updateMemory({
        accessToken,
        memory: memoryData,
      });
    } else {
      updatedMemory = await Api.createMemory({
        accessToken,
        memory: memoryData,
      });
    }

    setMemoryData(updatedMemory);
    return updatedMemory;
  };

  const deleteMemory = async (): Promise<void> => {
    await Api.deleteMemory({ accessToken, memory });
  };

  return [memoryData, setMemoryData, saveMemory, deleteMemory];
}
